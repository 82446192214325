@mixin initMobileSafariVariables() {
    --safe-area-inset-bottom: 0;
    --safe-area-inset-top: 0;
    --safe-area-inset-right: 0;
    --safe-area-inset-left: 0;
    --dynamic-viewport-height: 100vh;
    --dynamic-viewport-width: 100vw;

    // noinspection CssInvalidFunction
    @supports (padding-top: constant(safe-area-inset-top)) {
        --safe-area-inset-bottom: constant(safe-area-inset-bottom);
        --safe-area-inset-top: constant(safe-area-inset-top);
        --safe-area-inset-left: constant(safe-area-inset-left);
        --safe-area-inset-right: constant(safe-area-inset-right);
    }

    @supports (padding-top: env(safe-area-inset-top)) {
        --safe-area-inset-bottom: env(safe-area-inset-bottom);
        --safe-area-inset-top: env(safe-area-inset-top);
        --safe-area-inset-left: env(safe-area-inset-left);
        --safe-area-inset-right: env(safe-area-inset-right);
    }

    // noinspection CssInvalidPropertyValue
    @supports (height: 100dvh) {
        --dynamic-viewport-height: 100dvh;
        --dynamic-viewport-width: 100dvw;
    }
}
