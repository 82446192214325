@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

// TODO: Remove colorized-headline, change headline font-weight by default to normal, add modifier --bold, add "headline--bold" to all elements with class "headline", replace all "colorized-headline" with "headline"

.headline {
    font-weight: bold;
    color: $blueFire;
}

.colorized-headline {
    color: $blueFire;
    margin-bottom: $global-spacing;
}

.headline,
.colorized-headline {
    &--no-margin {
        margin-bottom: 0;
    }

    &--half-margin {
        margin-bottom: $global-spacing-small;
    }

    &--bodyColor {
        color: $silverStar;
    }

    &--hover {
        &:hover {
            cursor: pointer;
        }
    }

    &__part {
        display: inline-block;
        vertical-align: top;

        &--limited-size {
            max-width: 550px;
        }

        &:nth-child(n + 2) {
            margin-left: 5px;
        }
    }
}

.sub-headline {
    font-size: 16px;
}

.nowrap {
    white-space: nowrap;
}
