@import 'Application/view/framework/common/mixins/initMobileSafariVariables';
@import 'Application/view/framework/common/mixins/safeAreaInset';
@import 'Application/view/framework/common/settings';
@import 'Application/view/generated/zIndices';

// ************************************************************
// Foundation normalizes a lot of stylings, some of them break
// the wireframe, so we have to fix those here.
// ************************************************************

#c24-header,
#c24m-header {
    box-sizing: content-box;
    line-height: 16px;

    fieldset {
        padding: 0;
    }

    .c24-nav-ele {
        ul,
        dl,
        ol {
            line-height: 26px;
        }
    }
}

.c24-logo {
    z-index: zIndex(wireframeElements);
}

.c24-footer-logo,
.c24-social-links {
    box-sizing: content-box;
}

body.c24-sticky #c24-header {
    // Reset z-index of sticky header to a more reasonable value
    z-index: zIndex(c24wireframe);
}

.c24-search,
.c24-navi-search-sho-wrapper {
    fieldset {
        border: none;
        margin: 0;
        padding: 0;
    }
}

.c24-search-input {
    margin: 0 !important;
}

.c24-header-hover:not(.c24-customer-hover),
#c24m-search {
    box-sizing: content-box;
}

.c24-social-links li .c24-social-icon.c24-social-icon-twitter {
    margin-top: -5px;
}

.c24-contact-layer {
    z-index: zIndex(c24wireframe) + 1;
}

.c24m-contact-slogan {
    margin-bottom: $global-spacing-small;
}

.c24m-contact-slogan > span {
    font-size: rem-calc(18px);
    font-weight: bold;
}

.c24-phone > .c24-contact-icon {
    // For spanish wireframe so that there is a space between icon and phone number.
    // Do not change to .c24-contact-icon only because this would affect german wireframe.
    margin-right: 5px;
}

/*
 * @see https://jira.check24.de/browse/HOFE-9215
 * Fix size of wireframe svgs which do not size properly because of different box-sizing values.
 */
.c24m-cl-ele.c24m-cl-back,
.c24m-cl-pw-toggle.c24m-cl-pw-hide {
    box-sizing: content-box;
}

// Fix styles for smart app banner.
.c24m-sab {
    box-sizing: content-box;
    line-height: 16px;
}

.c24-customer-trigger {
    // Overcome user favorites z-index
    z-index: zIndex(wireframePopups);

    .c24-customer-layer {
        display: block;
    }
}

.c24-mylists * {
    box-sizing: content-box;
}

// Fix wrong back button sizing and positioning
.c24-uli-loginlayer {
    box-sizing: content-box;
}

// Fix size of flag icon in the top navigation for internet explorer
.c24-header .c24-flag .c24-flag-icon > svg,
.c24-header .c24-flag .c24-flag-icon-ger > svg {
    max-height: 20px;
    max-width: 30px;
}

.c24-footer-nav {
    margin-left: 0;
}

/**
 * Overwrites for the Cookie Consent
 * Wrapped inside of body to get a higher selector presence
 */
body {
    // The dark background layer
    .c24-blocking-layer,
    .c24-strict-blocking-layer {
        z-index: zIndex(c24wireframe);
    }

    .c24-cookie-consent-wrapper,
    .c24-cookie-consent-notice,
    .c24-cookie-consent-screen,
    .c24-uli-loginlayer {
        // 1 above the background layer
        z-index: zIndex(c24wireframe) + 1;
    }
}

:root {
    @include initMobileSafariVariables;
}

// German Desktop
body.c24-body,
// Spain Desktop
body.c24-wide,
// Blank layout, Desktop and Mobile
body.hoBlankLayoutBody {
    @include safeAreaInset(padding-top, padding-bottom, padding-left, padding-right);
}

@media (orientation: portrait) {
    // German Mobile, Spain Mobile
    body#c24m-body {
        @include safeAreaInset(padding-top, padding-bottom, padding-left, padding-right);
    }
}

@media print {
    #c24-page-container-header,
    #c24-page-container-footer,
    .c24m-footer,
    #c24m-header,
    #laminas-developer-toolbar,
    .c24m-cookie,
    .c24-cookie {
        display: none !important;
    }

    span.show-for-print {
        display: inline !important;
    }
}

// This is required as unified login on desktop for Spain is not responsive at this point.
:lang(es) {
    .c24-uli-body .c24-uli-wrapper {
        .c24-uli {
            width: 548px !important;
        }
    }
}

// This is our own element, but the wireframe
// styles all span elements in the mobile nav
// and we have to prevent this for our own span.
.c24m-navi .c24m-navi-header-bottom .wireframeUserSalutation {
    display: inline-block !important;
    padding: 0 !important;
}

.c24m-header-top-icons-left {
    display: flex;
}

// Used to overwrite position: fixed when updating viewport on iOS Tablets.
body.c24-cc-visible-overwrite {
    position: relative !important;
}

// Fix a bug on ios where the tapbar overlays the sliding menu
.c24m-tap-bar {
    z-index: zIndex(mobileOverlayElements);
}
