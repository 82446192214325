/**
    This uses CSS Filters, thus does not work in IE
    @see https://caniuse.com/#feat=css-filters
 */
@import 'Application/view/framework/common/mixins/lightedGrayscale';

$componentClass: fadedImage;

.#{$componentClass} {
    @include lightedGrayscale;

    transition: filter 1s;

    &:hover {
        filter: none;
    }
}
