@mixin svgTwigIcon() {
    // SVG icons directly used in twig
    .svgTwigIcon {
        // Copied from SvgIcon
        display: inline-flex;
        vertical-align: baseline;
        font-size: inherit;
        justify-content: center;
        width: 1em;
        height: 1em;
        overflow: hidden;
        stroke-width: 0;
        stroke: currentColor;
        fill: currentColor;

        > svg {
            height: 100%;
        }
    }
}
