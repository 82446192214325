@import 'Application/view/framework/common/settings';

.c24hContentWrapper {
    margin: 0 auto;
    padding: 0 $global-spacing;
    max-width: $global-width;

    @media screen and (min-width: $global-width) {
        max-width: $global-expanded-width;
    }

    @media screen and (min-width: ($global-expanded-width + (2 * $global-spacing))) {
        padding: 0;
    }

    &--reducedWidth {
        max-width: $global-width;
    }
}

.c24hGapContainer {
    display: flex;
    flex-direction: column;
    row-gap: $landingpage-top-level-element-spacing;

    > div:empty {
        display: none;
    }
}

.clearfix {
    @include clearfix;
}

a {
    outline: none;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}
