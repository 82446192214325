[type='color'],
[type='date'],
[type='datetime-local'],
[type='datetime'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'] {
    height: ($input-font-size * unitless-calc($global-line-height)) + ($input-padding-vertical * 2) + rem-calc($input-border-width * 2);
    border-radius: 0;
    touch-action: manipulation; // fix for 300ms delay on touch devices
}

textarea {
    border-radius: 0;
}

::-ms-clear,
::-ms-reveal {
    display: none;
}
