@import 'Application/view/generated/colors';
@import 'Application/view/framework/common/settings';

#c24-footer-info {
    & .c24-footer-info-list.minimal {
        text-align: right;

        & li {
            display: inline;
            list-style: none;
        }
    }

    & .impress {
        text-align: center;

        & a {
            color: $silverStar;
        }
    }
}
