@import 'Application/view/generated/colors';

/* stylelint-disable selector-no-qualifying-type */
body {
    // Prevent adjustments of font size after orientation changes in iOS.
    text-size-adjust: none;

    &.body {
        &--greyBackground {
            background: $alabaster;
        }

        &--customerArea {
            height: auto;

            // Need to overwrite this because the customer area iframe has a width of maximum 994px but
            // wireframe layout css sets this to a min-width of 1024px.
            min-width: 0;

            & #c24-page-and-ads {
                min-width: 0;

                // Tablet in portrait mode can not show the whole content.
                // Reduce width to show borders of content on both sides.
                @media only screen and (width <= 768px) {
                    max-width: 98%;
                }
            }

            // We have no absolute positioned footer in the Thirdview, so we have to reduce the bottom padding to have less whitespace
            & #c24-page-container-content {
                padding-bottom: 50px;
            }
        }
    }

    &#c24m-body {
        &.body {
            // Grey background on mobile is a bit different than on desktop
            &--greyBackground {
                background: $almostWhite;
            }
        }
    }
}
/* stylelint-enable selector-no-qualifying-type */
